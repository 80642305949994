import UserServices from "../services/UserServices";

export default async function UpdateXpUser() {
  let UserService = new UserServices();

  var response = await UserService.getUserIndicators();

  const progress =  response.data.progression_points / response.data.next_points_progression * 100

  return {
    progress,
    class_name: response.data.class_name,
    level_name: response.data.level_name,
  };
}