import Notify from 'quasar/src/plugins/Notify.js';;

export default class CanUserAccessClass {
    canUserAccess(permission, notify = true){
        let array = JSON.parse(localStorage.getItem("vuex")).permissions;
        let result = array.find(element => {
            return element.routeName === permission;
        });
        if(!result){
            if(notify){
                Notify.setDefaults({
                    position: 'top',
                    timeout: 5000,
                    textColor: 'white',
                    color: 'red-9'
                })

                Notify.create('Você não tem permissão para executar está ação.')
            }
            return false;
        }
        return true;
    }


    canUserAccessWithPermissions(permissions, notify = true){
        const PERMISSIONS = JSON.parse(localStorage.getItem("vuex")).permissions;

       const validation =  PERMISSIONS.find((PERMISSION) => {
           if (permissions.includes(PERMISSION.routeName))  {
               return true;
           }
        })

        if (!validation){
            if(notify){
                Notify.setDefaults({
                    position: 'top',
                    timeout: 5000,
                    textColor: 'white',
                    color: 'red-9'
                })
                Notify.create('Você não tem permissão para executar está ação.')
            }
            return false;
        }
        return true;
    }
}