import setCssVar from 'quasar/src/utils/set-css-var.js';;

export default function rexonTheme() {
    setCssVar("primary", "#1A1A1A");
    setCssVar("secundary", "#D4A812");
    setCssVar("secondary", "#D4A812");



    setCssVar("grad-1", "#ffc501");
    setCssVar("grad-2", "#cba011");

    setCssVar("secundary-rgb", "254, 82, 104");

    // setCssVar("accent", "#FE5268");

    // setCssVar("dark", "#FE5268");

    // setCssVar("positive", "#FE5268");
    // setCssVar("negative", "#FE5268");
    // setCssVar("info", "#FE5268");
}
