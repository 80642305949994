import Layout from "../../views/Layout.vue";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

const canUserAccessClass = new CanUserAccessClass();


export default {
  path: "/games",
  name: "games",
  component: Layout,
  redirect: { path: "/home" },
  children: [
    {
      path: "/launcher",
      name: "launcher",
      component: () => import("@/pages/games/Launcher.vue"),
      beforeEnter: (to, from, next) => {
        if (canUserAccessClass.canUserAccess(to.name)) {
          next();
        } else {
          next({ name: from.name });
        }
      },
    },
  ],
};
