import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { ENV } from "./env";

export default class Socket {
  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(ENV.BASE_URL_SOCKET, {
        accessTokenFactory: () => localStorage.getItem("token"),
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }
  async disconnect() {
   await this.connection.stop();
  }
}
