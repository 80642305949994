import setCssVar from 'quasar/src/utils/set-css-var.js';;

export default function sesiTheme() {
    setCssVar("primary", "#1A1A1A");
    setCssVar("secundary", "hsl(136, 99%, 33%)");
    setCssVar("secondary", "hsl(136, 99%, 33%)");

    setCssVar("grad-1", "#004498");
    setCssVar("grad-2", "hsl(136, 99%, 33%)");

    setCssVar("secundary-rgb", "254, 82, 104");

    // setCssVar("accent", "#FE5268");

    // setCssVar("dark", "#FE5268");

    // setCssVar("positive", "#FE5268");
    // setCssVar("negative", "#FE5268");
    // setCssVar("info", "#FE5268");
}
